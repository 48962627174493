// Imports
// --------------------------------------
// @import 'https://fonts.googleapis.com/css?family=Noto+Sans';
// Variables
// --------------------------------------
$scrollbar-width: 5px;
$chat-thread-bgd-color: rgba(25, 147, 147, 0.2);
$chat-thread-msg-arrow-size: 10px;
$chat-thread-avatar-size: 25px;
$chat-thread-offset: #{$chat-thread-avatar-size + 20px};
$default-shadow-color: rgb(0, 0, 0);

// Mixins
// --------------------------------------
@mixin fancy-background() {
    background: -moz-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
    background: -webkit-linear-gradient(-45deg, #183850 0, #183850 25%, #192C46 50%, #22254C 75%, #22254C 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
}

@mixin fancy-scrollbar() {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }

    &::-webkit-scrollbar-track {
        border-radius: $scrollbar-width;
        background-color: rgba(25, 147, 147, 0.1);
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-width;
        background-color: $chat-thread-bgd-color;
    }
}

@mixin scrolling-shadows($background-color: transparent, $shadow-intensity: 0.5, $shadow-color: $default-shadow-color, $cover-size: 40px, $shadow-size: 14px) {
    // Shadow covers
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)), radial-gradient(50% 100%, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)) 0 100%;
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0));
    // also add button shadow:
    //radial-gradient(farthest-side at 50% 100%, rgba($shadow-color,$shadow-intensity), rgba($shadow-color,0)) 0 100%;
    background-repeat: no-repeat;
    background-color: $background-color;
    background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;
    // Opera doesn't support this in the shorthand
    background-attachment: local, local, scroll, scroll;
}

* {
    box-sizing: border-box;
}

// body {
//     background: skyblue;
//     font: 12px/16px 'Noto Sans', sans-serif;
// }

.floating-chat {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    transform: translateY(70px);
    transition: all 250ms ease-out;
    border-radius: 50%;
    opacity: 0;
    @include fancy-background;

    &.enter:hover {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        opacity: 1;
    }

    &.enter {
        transform: translateY(0);
        opacity: 0.6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.14);
    }

    &.expand {
        width: 250px;
        max-height: 400px;
        height: 400px;
        border-radius: 5px;
        cursor: auto;
        opacity: 1;
    }

    :focus {
        outline: 0;
        box-shadow: 0 0 3pt 2pt rgba(#0EC879, 0.3);
    }

    button {
        background: transparent;
        border: 0;
        color: white;
        text-transform: uppercase;
        border-radius: 3px;
        cursor: pointer;
    }

    .chat {
        display: flex;
        flex-direction: column;
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        transition: all 250ms ease-out;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.enter {
            opacity: 1;
            border-radius: 0;
            margin: 10px;
            width: auto;
            height: auto;
        }

        .header {
            flex-shrink: 0;
            padding-bottom: 10px;
            display: flex;
            background: transparent;

            .title {
                flex-grow: 1;
                flex-shrink: 1;
                padding: 0 5px;
            }

            button {
                flex-shrink: 0;
            }
        }

        .messages {
            padding: 10px;
            margin: 0;
            list-style: none;
            overflow-y: scroll;
            overflow-x: hidden;
            flex-grow: 1;
            border-radius: 4px;
            background: transparent;
            @include fancy-scrollbar;

            li {
                position: relative;
                clear: both;
                display: inline-block;
                padding: 14px;
                margin: 0 0 20px 0;
                font: 12px/16px 'Noto Sans', sans-serif;
                border-radius: 10px;
                background-color: $chat-thread-bgd-color;
                word-wrap: break-word;
                max-width: 81%;

                &:before {
                    position: absolute;
                    top: 0;
                    width: $chat-thread-avatar-size;
                    height: $chat-thread-avatar-size;
                    border-radius: $chat-thread-avatar-size;
                    content: '';
                    background-size: cover;
                }

                &:after {
                    position: absolute;
                    top: $chat-thread-msg-arrow-size;
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: $chat-thread-msg-arrow-size solid $chat-thread-bgd-color;
                }
            }

            li.other {
                animation: show-chat-odd 0.15s 1 ease-in;
                -moz-animation: show-chat-odd 0.15s 1 ease-in;
                -webkit-animation: show-chat-odd 0.15s 1 ease-in;
                float: right;
                margin-right: $chat-thread-offset;
                color: #0AD5C1;
            }

            li.other:before {
                right: -$chat-thread-offset;
                // Placeholder avatar 1
                background-image: url(https://github.com/Thatkookooguy.png);
            }

            li.other:after {
                border-right: $chat-thread-msg-arrow-size solid transparent;
                right: -$chat-thread-msg-arrow-size;
            }

            li.self {
                animation: show-chat-even 0.15s 1 ease-in;
                -moz-animation: show-chat-even 0.15s 1 ease-in;
                -webkit-animation: show-chat-even 0.15s 1 ease-in;
                float: left;
                margin-left: $chat-thread-offset;
                color: #0EC879;
            }

            li.self:before {
                left: -$chat-thread-offset;
                // Placeholder avatar 2
                background-image: url(https://github.com/ortichon.png);
            }

            li.self:after {
                border-left: $chat-thread-msg-arrow-size solid transparent;
                left: -$chat-thread-msg-arrow-size;
            }
        }

        .footer {
            flex-shrink: 0;
            display: flex;
            //flex-direction: row-reverse;
            padding-top: 10px;
            max-height: 90px;
            background: transparent;

            .text-box {
                border-radius: 3px;
                background: $chat-thread-bgd-color;
                min-height: 100%;
                width: 100%;
                margin-right: 5px;
                color: #0EC879;
                overflow-y: auto;
                padding: 2px 5px;
                @include fancy-scrollbar;
            }
        }
    }
}

// Animation
// --------------------------------------
@keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@-moz-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@-webkit-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
}

@-moz-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
}

@-webkit-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
}